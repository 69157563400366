/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, ColumnWrap, ColumnWrapper, TitleMain, Title, ColumnCard, Text, Image, Subtitle, PriceWrap, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="css-xk8zf5 --full pb--60 pt--60" name={"hlavicka"} style={{"backgroundColor":"rgba(0,0,0,1)"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8305/06e994481d2343c7970c1c4d9c0ee9ca_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box fs--16 w--400 title-box--invert lh--12 mt--02" content={"Štípání dřeva Biskupice - VZagro.cz"}>
              </TitleMain>

              <Title className="title-box fs--72 title-box--invert lh--12 mt--02" content={"Dělat dřevo už není pakárna. S námi pohodlně a bez práce."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"b9fu7bfdqxd"} layout={"l21"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"&nbsp;<span style=\"font-style: italic;\">VZagro</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnCard className="column__card">
              
              <ColumnWrapper className="--shadow pb--16 pt--16" style={{"backgroundColor":"#ffffff"}}>
                
                <Text className="text-box fs--72 lh--16" style={{"fontStyle":"italic"}} content={"<span style=\"color: rgb(0, 0, 0);\">Těžba a štípání dřeva<br>Zemědělské služby</span><br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnCard>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--20 pt--60" name={"vymena-pneu"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":546}} content={"Štípání&nbsp; dřeva"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":500}} content={"Nabízíme štípání dřeva se štípacím automatem&nbsp; Hakki Pilke. Ať už máte dřevo v délkách nebo nařezané v metrech, nic není problém. Jen si vyberete velikost polínek podle vašich kamínek. Délka polínek je možná od 25 cm do 60 cm a do průměru klády 37 cm. Řeknete na kolik dílků chcete dřevo štípat, buď na půlky, čtvrtiny nebo šestiny a zbytek je už na nás."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--0" src={"https://cdn.swbpg.com/t/8305/e7aafcd808b0447fa59b4819f3edde50_s=860x_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/8305/e7aafcd808b0447fa59b4819f3edde50_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/e7aafcd808b0447fa59b4819f3edde50_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/e7aafcd808b0447fa59b4819f3edde50_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/e7aafcd808b0447fa59b4819f3edde50_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/e7aafcd808b0447fa59b4819f3edde50_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--60" name={"pneu-sluzby"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3 pb--20 pt--20" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/6ba6231c1c3d460dbe11d5e09f7a3df6_s=860x_.jpg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/8305/6ba6231c1c3d460dbe11d5e09f7a3df6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/6ba6231c1c3d460dbe11d5e09f7a3df6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/6ba6231c1c3d460dbe11d5e09f7a3df6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/6ba6231c1c3d460dbe11d5e09f7a3df6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/6ba6231c1c3d460dbe11d5e09f7a3df6_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Manipulace dřeva"}>
              </Subtitle>

              <Text className="text-box" content={"Nabízíme také stahování dřeva v lese. Cena je na dohodě."}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/6f277ea240ff4a93aeac47ac572e3fe5_s=860x_.jpg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/8305/6f277ea240ff4a93aeac47ac572e3fe5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/6f277ea240ff4a93aeac47ac572e3fe5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/6f277ea240ff4a93aeac47ac572e3fe5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/6f277ea240ff4a93aeac47ac572e3fe5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/6f277ea240ff4a93aeac47ac572e3fe5_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Vyvážení dřeva vyvážečkou"}>
              </Subtitle>

              <Text className="text-box" content={"Pokud vám leží dřevo v lese, není problém vám je dovést a složit kam budete potřebovat."}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/d4150baba9d84abdbb7d62b20bffa636_s=860x_.jpg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/8305/d4150baba9d84abdbb7d62b20bffa636_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/d4150baba9d84abdbb7d62b20bffa636_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/d4150baba9d84abdbb7d62b20bffa636_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/d4150baba9d84abdbb7d62b20bffa636_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/d4150baba9d84abdbb7d62b20bffa636_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Svážení balíků samonakládacím vozem"}>
              </Subtitle>

              <Text className="text-box" content={"Jedná se o vůz s pohyblivým ramenem, který si balíky sám naloží a na určeném místě vysype. Vleze se na něj 8 kulatých balíků."}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1fsyuso bg--center mb--20 pb--30 pt--80" name={"cenik"} style={{"backgroundColor":"rgba(255,255,255,1)"}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/8305/07efd168c0a3485da5baaecb00d4c13e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Základní ceník<br>&nbsp;služeb</span>"}>
              </Title>

              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--60" name={"polozky"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="mt--0 pb--0 pt--0">
              
              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Ceny&nbsp; dohodou<br><br>"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                </ColumnWrapper>

                <ColumnWrapper className="--right">
                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--80 pt--80" name={"zkusenosti"} style={{"backgroundColor":"rgba(31, 34, 40, 1)"}}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--invert" content={"Pár fotek"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"d7xdvv5o6le"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/cdb56bf6423a48acb1cf5a286e73ffc4_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/cdb56bf6423a48acb1cf5a286e73ffc4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/cdb56bf6423a48acb1cf5a286e73ffc4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/cdb56bf6423a48acb1cf5a286e73ffc4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/cdb56bf6423a48acb1cf5a286e73ffc4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/cdb56bf6423a48acb1cf5a286e73ffc4_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/3d5000d9391c4d7d90d7242a51073953_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/3d5000d9391c4d7d90d7242a51073953_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/3d5000d9391c4d7d90d7242a51073953_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/3d5000d9391c4d7d90d7242a51073953_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/3d5000d9391c4d7d90d7242a51073953_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/3d5000d9391c4d7d90d7242a51073953_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/a4d987b85a174d7285a4f700d5cac70c_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/a4d987b85a174d7285a4f700d5cac70c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/a4d987b85a174d7285a4f700d5cac70c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/a4d987b85a174d7285a4f700d5cac70c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/a4d987b85a174d7285a4f700d5cac70c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/a4d987b85a174d7285a4f700d5cac70c_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"xalrrnpuku"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/2a0215037f804364bda73f52b4828771_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/2a0215037f804364bda73f52b4828771_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/2a0215037f804364bda73f52b4828771_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/2a0215037f804364bda73f52b4828771_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/2a0215037f804364bda73f52b4828771_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/2a0215037f804364bda73f52b4828771_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/8f038ccf076341c68d72ae16a91305fc_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/8f038ccf076341c68d72ae16a91305fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/8f038ccf076341c68d72ae16a91305fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/8f038ccf076341c68d72ae16a91305fc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/8f038ccf076341c68d72ae16a91305fc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/8f038ccf076341c68d72ae16a91305fc_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Náš usměvavý Jakub"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/0b7909c701674f488c675fa7aeff2c33_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/0b7909c701674f488c675fa7aeff2c33_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/0b7909c701674f488c675fa7aeff2c33_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/0b7909c701674f488c675fa7aeff2c33_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/0b7909c701674f488c675fa7aeff2c33_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/0b7909c701674f488c675fa7aeff2c33_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"b4d82zdm3uk"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/d1f8141f551f47d198527bd935a13a1e_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/d1f8141f551f47d198527bd935a13a1e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/d1f8141f551f47d198527bd935a13a1e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/d1f8141f551f47d198527bd935a13a1e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/d1f8141f551f47d198527bd935a13a1e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/d1f8141f551f47d198527bd935a13a1e_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/cca910d12e4142c4b0ae07c61da444dc_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/cca910d12e4142c4b0ae07c61da444dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/cca910d12e4142c4b0ae07c61da444dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/cca910d12e4142c4b0ae07c61da444dc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/cca910d12e4142c4b0ae07c61da444dc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/cca910d12e4142c4b0ae07c61da444dc_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/8305/b158a660e8b241fd8b0aa394a4177938_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/8305/b158a660e8b241fd8b0aa394a4177938_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8305/b158a660e8b241fd8b0aa394a4177938_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8305/b158a660e8b241fd8b0aa394a4177938_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8305/b158a660e8b241fd8b0aa394a4177938_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8305/b158a660e8b241fd8b0aa394a4177938_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--80 pt--80" name={"reference2"} style={{"backgroundColor":"rgba(31, 34, 40, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"Neváhejte nás kontaktovat,&nbsp; určitě se domluvíme."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"kontakt"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic;\">VZ</span>agro<br><br>Vladimír Zecha"}>
              </Subtitle>

              <Text className="text-box" content={"Biskupice 43<br>Jevíčko<br>tel.kontakt : 736 232 584<br>email : vzagro@seznam.cz<br>IČO : 07966075<br>"}>
              </Text>

              <Subtitle className="subtitle-box mt--16" style={{"maxWidth":300}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style3 mt--0" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Biskupice43"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}